













































import { Component, Emit, Prop } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextarea from "@/components/ui/ValidationTextarea.vue";

import { ProductCategory } from "@/models/Product";
import ProductApi from "@/apis/product/ProductApi";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationTextarea,
  },
})
export default class EditForm extends BaseView {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: ProductCategory) {
    return data;
  }

  @Prop() editedItem!: ProductCategory;

  private productApi: ProductApi = new ProductApi();

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;
      try {
        await this.productApi.updateCategory(this.editedItem);
        this.submit(this.editedItem);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isConnecting = false;
      }
    }
  }
}
