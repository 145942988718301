


















































import { Component, Emit } from "vue-property-decorator";
import BaseView from "@/views/BaseView.vue";
import BaseForm from "@/components/dialog/BaseForm.vue";
import ValidationTextField from "@/components/ui/ValidationTextField.vue";
import ValidationSelect from "@/components/ui/ValidationSelect.vue";
import ValidationTextarea from "@/components/ui/ValidationTextarea.vue";
import ProductApi, {
  CategoryCreateRequestParams,
} from "@/apis/product/ProductApi";
import { ProductCategory } from "@/models/Product";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    BaseForm,
    ValidationTextField,
    ValidationSelect,
    ValidationTextarea,
  },
})
export default class CreateForm extends BaseView {
  $refs!: {
    observer: InstanceType<typeof ValidationObserver>;
  };
  @Emit()
  private cancel() {
    return false;
  }

  @Emit()
  private submit(data: ProductCategory) {
    return data;
  }

  private productApi: ProductApi = new ProductApi();
  private params!: CategoryCreateRequestParams;

  private created() {
    this.params = { ...this.params };
  }

  private async save() {
    const result = await this.$refs.observer.validate();
    if (result) {
      this.isConnecting = true;
      try {
        const repo = await this.productApi.createCategory(this.params);
        this.submit(repo.data);
      } catch (e) {
        this.handleError(e);
      } finally {
        this.isConnecting = false;
      }
    }
  }
}
