

























































































import ProductApi from "@/apis/product/ProductApi";
import Pagenation from "@/models/Pagenation";
import { ProductCategory } from "@/models/Product";
import BaseView from "@/views/BaseView.vue";
import { Component } from "vue-property-decorator";
import CreateForm from "./forms/CreateCategoryForm.vue";
import EditForm from "./forms/EditCategoryForm.vue";

@Component({
  components: {
    CreateForm,
    EditForm,
  },
})
export default class Main extends BaseView {
  private Headers = [
    { text: "No", value: "id" },
    {
      text: "카테고리",
      value: "name",
      sortable: false,
    },
    {
      text: "판매유형",
      value: "type",
    },
    {
      text: "사용 유무",
      value: "enabled",
    },
    { text: "설정", value: "actions", sortable: false },
  ];

  private isCreating = false;
  private isEditing = false;
  private editedItem!: ProductCategory;

  private ProductApi: ProductApi = new ProductApi();
  private pagenation = new Pagenation();
  private items: ProductCategory[] = [];

  protected created() {
    this.loadDatas();
  }

  private async loadDatas() {
    try {
      const repo = await this.ProductApi.getCategories();
      this.items = [...repo.data];
    } catch (e) {
      this.handleError(e);
    }
  }

  private onClickDelete(requestItem: ProductCategory) {
    if (requestItem.product_count > 0) {
      this.$alert("알림", "해당 카테고리에 등록 된 제품이 있습니다.");
      return;
    }
    this.$confirm("알림", "삭제하시겠습니까?", async (result: boolean) => {
      if (!result) return;
      try {
        this.$showProgress();
        await this.ProductApi.deleteCategory(requestItem.id);
        const items = [...this.items];
        const idx = items.findIndex((item) => item.id === requestItem.id);
        items.splice(idx, 1);
        this.items = items;
        this.$showSnack("해당 카테고리가 삭제되었습니다.");
        this.$hideProgress();
      } catch (e) {
        this.handleError(e);
      }
    });
  }

  private onClickCreated() {
    this.isCreating = true;
  }

  private onClickEdit(item: ProductCategory) {
    this.isEditing = true;
    this.editedItem = { ...item };
  }

  private onAddItem(requestItem: ProductCategory) {
    this.isCreating = false;
    this.items.push(requestItem);
  }

  private onEditItem(requestItem: ProductCategory) {
    this.isEditing = false;
    const items = [...this.items];
    const idx = items.findIndex((item) => item.id === requestItem.id);
    items[idx] = { ...requestItem };
    this.items = items;
    this.$showSnack("카테고리가 수정되었습니다.");
  }
}
